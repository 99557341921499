import React, { useState } from "react";
import "./categorySlider.css";
import { EmptyImage } from "../../Assets";
import EditCategoryPopup from "../EditCategoryPopup";
import { URL } from "../../Constants/Constant";
import { useNavigate } from "react-router-dom";

const CategorySlider = ({ isAdd, item }) => {
  const navigate = useNavigate();
  let [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          navigate("/ProductsByCategory", { state: { id: item.id } });
        }}
        className={
          isAdd
            ? "categorySliderMainContainerWrapper"
            : "categorySliderMainContainer"
        }
      >
        <img
          src={item ? URL.concat(item.image) : EmptyImage}
          alt="img"
          className="categoryLogoImageStyle"
        />
        <div className="CategoryText">{item && item.category_name}</div>
        {isAdd ? (
          <div className="CategoryButtonWrapper">
            <div className="CatButtonStyle1">Delete</div>
            <div
              className="CatButtonStyle"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit
            </div>
          </div>
        ) : null}
      </div>
      <EditCategoryPopup setIsEdit={setIsEdit} isEdit={isEdit} />
    </>
  );
};

export default CategorySlider;
