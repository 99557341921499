import React, { useState, useEffect } from "react";
import "./home.css";
import CategorySlider from "../../Components/CategorySlider/CategorySlider";
import ProductComp from "../../Components/ProductComponant/ProductComp";
import {
  mobile1,
  mobile2,
  mobile3,
  mobile4,
  mobile5,
  mobile6,
  mobile7,
  mobile8,
} from "../../Assets";
import { FaSearch } from "react-icons/fa";
import {
  getAllCategoriesApiCall,
  getAllProductsApiCall,
} from "../../Apis/repo";
import LoaderComp from "../../Components/Loader/Loader";

const Home = () => {
  const Data = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
    {
      id: 9,
    },
    {
      id: 10,
    },
    {
      id: 11,
    },
    {
      id: 12,
    },
  ];

  const array = [
    {
      id: 1,
      image: mobile1,
      heading: "Techno",
    },
    {
      id: 2,
      image: mobile2,
      heading: "OPPO",
    },
    {
      id: 3,
      image: mobile3,
      heading: "VIVO",
    },
    {
      id: 4,
      image: mobile4,
      heading: "Huawei",
    },
    {
      id: 5,
      image: mobile5,
      heading: "Nokia",
    },
    {
      id: 6,
      image: mobile6,
      heading: "Samsung",
    },
    {
      id: 7,
      image: mobile7,
      heading: "Iphone",
    },
    {
      id: 8,
      image: mobile8,
      heading: "Nokia",
    },
    {
      id: 9,
      image: mobile2,
      heading: "OPPO",
    },
    {
      id: 10,
      image: mobile3,
      heading: "VIVO",
    },
  ];

  const [allCategoriesList, setAllCategoriesList] = useState([]);
  let [allProductList, setAllProductList] = useState([]);
  let [refresh, setRefresh] = useState(false);
  let [loader, setLoader] = useState(false);
  let [loaderOne, setLoaderOne] = useState(false);
  let [search, setSearch] = useState("");

  useEffect(() => {
    getAllCategories();
    getAllProducts();
  }, [refresh]);

  const getAllCategories = () => {
    setLoader(true);
    getAllCategoriesApiCall()
      .then(({ data }) => {
        setLoader(false);
        if (data.error_code == 0) {
          setAllCategoriesList(data.result);
        } else {
          alert(data.messaage);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const getAllProducts = () => {
    setLoaderOne(true);
    getAllProductsApiCall("", search)
      .then(({ data }) => {
        debugger
        setLoaderOne(false);
        if (data.error_code == 0) {
          setAllProductList((allProductList = data.result));
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        setLoaderOne(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <section className="HomeMainHeaderContainer">
        <div className="Home_All_Category_Text_Style1">All Categories</div>
        <div className="HomeCategoryMainWrapper">
          {allCategoriesList.map((item) => {
            return <CategorySlider item={item} />;
          })}
        </div>
        <div className="Home_Search_Container">
          <div className="Home_All_Category_Text_Style">All Products</div>
          <div className="search_container">
            <FaSearch size={18} className="search_icon" color="#7b7b7b" />
            <input
              type="text"
              placeholder="Search..."
              className="search_input"
              onChange={(e) => {
                setSearch((search = e.target.value));
                getAllProducts();
              }}
            />
          </div>
        </div>
        <div className="HomeSelectCategoryDataShow">
          {allProductList.map((item) => {
            return <ProductComp item={item} />;
          })}
        </div>
      </section>

      {loader || loaderOne ? <LoaderComp /> : null}
    </>
  );
};

export default Home;
