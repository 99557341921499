import React, { useState, useEffect } from "react";
import "./productDetail.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import CardSlider from "../../Components/CardSlider/CardSlider";
import { getAllProductsApiCall } from "../../Apis/repo";
import { useLocation } from "react-router-dom";
import { URL } from "../../Constants/Constant";
import { EmptyImage } from "../../Assets";
import LoaderComp from "../../Components/Loader/Loader";

const Productdetail = () => {
  const location = useLocation();
  const { state } = location;
  const { id } = state || {};
  const [activeSlide, setActiveSlide] = useState(0);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  let [productData, setProductData] = useState("");
  let [loader, setLoader] = useState(false);

  useEffect(() => {
    getProductById();
  }, []);

  const getProductById = () => {
    setLoader(true);
    getAllProductsApiCall(id)
      .then(({ data }) => {
        debugger;
        setLoader(false);
        if (data.error_code == 0) {
          setProductData(data.result);
        } else {
          alert(data.messaage);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 500,
          // height:'100vh'
        }}
      >
        {loader ? <LoaderComp /> : null}
      </div>
      {/* {loader ? null :  */}
      <div className="ProductDetailMainContainer">
        {loader ? null : (
          <div className="PD_Image_MainRapper">
            <div className="FeatureButtonContainer">
              <div ref={navigationPrevRef}>
                <MdKeyboardArrowLeft
                  color={"black"}
                  size={50}
                  className="ProductIconStyle"
                />
              </div>
              <div ref={navigationNextRef}>
                <MdKeyboardArrowRight
                  color={"black"}
                  size={50}
                  className="ProductIconStyle"
                />
              </div>
            </div>
            <CardSlider
              images={productData.product_id_FK_images}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              navigationPrevRef={navigationPrevRef}
              navigationNextRef={navigationNextRef}
            />
          </div>
        )}
        <div className="PD_Text_MainContainer">
          <div className="PD_NameTextStyle">
            {productData && productData.product_name}
          </div>
          <div className="PD_PriceTextStyle">
            {"Rs " + productData && productData.product_price}
          </div>
          <div className="PD_DescriptionTextStyle">
            {productData && productData.description}
          </div>
        </div>
      </div>
      {/* } */}
    </>
  );
};
export default Productdetail;
