import React from "react";
import "./footer.css";
import { TfiEmail } from "react-icons/tfi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";

import {
  BiLogoFacebookSquare,
  BiLogoWhatsappSquare,
  BiLogoInstagramAlt,
} from "react-icons/bi";

import {
  FaceBookIcon,
  InstagramIcon,
  PartnerLogo,
  WhatsUpIcon,
} from "../../Assets";
import { NavLink, useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  const navigateToFacebook = () => {
    const facebookUrl = "https://www.facebook.com/your-facebook-page";
    window.open(facebookUrl, "_blank");
  };

  const navigateToWhatsAppChat = () => {
    const phoneNumber = '+1234567890'; // Replace with your phone number
    const messageText = 'Hello, I would like to chat with you.'; // Replace with your message text
    const whatsAppUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(messageText)}`;
    // window.location.href = whatsAppUrl;
    window.open(whatsAppUrl, "_blank");
  };

  const navigateToInstagramProfile = () => {
    const instagramUrl = 'https://www.instagram.com/your-instagram-profile'; // Replace with your Instagram profile URL
    window.open(instagramUrl, "_blank");
  };

  return (
    <div className="MainFooterContainer">
      <div className="FooterChildContainerRow">
        <div className="ChildSubContainerVer1">
          <img src={PartnerLogo} alt="Image" className="FootLogoStyle" />
          <div className="RowBlock1">
            <ImLocation2
              color={"#1dafa1"}
              size={16}
              className="InfoIconStyle"
            />
            <p className="RowBlockText">
              Building Hafeez center
              <br />
              shop 444 basement lahroe,pakistan
            </p>
          </div>
          <div className="RowBlock2">
            <BsFillTelephoneFill
              color={"#1dafa1"}
              size={16}
              className="InfoIconStyle"
            />
            <p className="RowBlockText">03214548447</p>
          </div>
          <div className="RowBlock2">
            <TfiEmail color={"#1dafa1"} size={16} className="InfoIconStyle" />
            <p className="RowBlockText">support@dreamwell.com</p>
          </div>
        </div>

        <div className="ChildSubContainerVer2">
          <p className="FooterHeading">Quick Links</p>
          <NavLink
            className="QuickLinksStyle"
            to="/Faqs"
            style={({ isActive }) => {
              return {
                borderBottom: isActive ? "1px solid #1dafa1" : null,
              };
            }}
          >
            FAQ's
          </NavLink>
          {/* <NavLink
            className="QuickLinksStyle"
            to="/Privacy"
            style={({ isActive }) => {
              return {
                borderBottom: isActive ? "1px solid #1dafa1" : null,
              };
            }}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            className="QuickLinksStyle"
            to="/Terms"
            style={({ isActive }) => {
              return {
                borderBottom: isActive ? "1px solid #1dafa1" : null,
              };
            }}
          >
            Terms & Conditions
          </NavLink> */}
          <NavLink
            className="QuickLinksStyle"
            to="/Warranty"
            style={({ isActive }) => {
              return {
                borderBottom: isActive ? "1px solid #1dafa1" : null,
              };
            }}
          >
            Warranty & Returns
          </NavLink>
          <NavLink
            className="QuickLinksStyle"
            to="/PaymentInfo"
            style={({ isActive }) => {
              return {
                borderBottom: isActive ? "1px solid #1dafa1" : null,
              };
            }}
          >
            Payment Information
          </NavLink>
        </div>

        <div className="ChildSubContainerVer4">
          {/* <div className="LastBlockHead">
            Get the Latest Information about properties from{" "}
            <span className="MonarchText"> MONARCH INTERNATIONAL</span>
          </div> */}
          <div className="FootText1">Need any Help!</div>
          <div className="FootText2">Call us</div>
          <div className="PhoneNumberStyle">+923214548447</div>
        </div>
      </div>
      <div className="UnderLineStyle" />
      <div className="FooterBaseMainContainerRow">
        <div className="BaseChildContainerRow1">
          <div className="FollowUs">Follow Us</div>
        </div>
        <div className="BaseChildContainerRow2">
          <div
            onClick={() => {
              navigateToFacebook();
            }}
          >
            <BiLogoFacebookSquare
              color={'green'}
              size={35}
              className="IconStyle"
            />
          </div>

          <div
            onClick={() => {
              navigateToWhatsAppChat();
            }}
          >
            <BiLogoWhatsappSquare
              color={'green'}
              size={35}
              className="IconStyle"
            />
          </div>

          <div
            onClick={() => {
              navigateToInstagramProfile();
            }}
          >
            <BiLogoInstagramAlt
              color={'green'}
              size={35}
              className="IconStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
