import React, { useState } from "react";
import "./faqs.css";

const Faqs = () => {

  const navigateToGoogleMapsLocation = () => {
    const location = 'Hafeez center'; // Replace with your location
    const googleMapsUrl = `https://www.google.com/maps/search/${encodeURIComponent(location)}`;
    window.open(googleMapsUrl, "_blank");
  };


  return (
    <>
      <section className="FAqMainHeaderContainer">
        <div className="faqsHeadingStyle">FAQs</div>
        <div className="faqsDetaiSubContainer">
          <div className="heaidngstylefaqsscreen">SHOPPING INFORMATION</div>
          <div className="heaidngstylefaqsscreen">
            Are your Products Genuine/Original?
          </div>
          <div className="textstylefaqsscreen">
            At HafeezCenterOnlineonline.pk, we believe in originality, We only
            sell pin-packed PTA Approved Phones. Each and every product you find
            at Partner Mobiles, whether it’s for Rs. 200 or Rs. 200,000,
            is 100% genuine & original.
          </div>

          <div onClick={()=>{navigateToGoogleMapsLocation()}} className="locationtextStyle">Where are you Located?</div>
          <div className="textstylefaqsscreen">
            You can enjoy shopping at Hafeezcentreonline.pk Head office, where
            you can self pick your Product 31-A1 Johar town Lahore
          </div>
          <div  className="heaidngstylefaqsscreen">What is the
            Delivery Time? </div>
            <div className="textstylefaqsscreen">While we try to get your product into your hands as
            fast as possible, all online orders usually take 24-72 hours to be
            there. In extremely rare cases, such as when we specially import a
            product for you, it may take up to 5 working days.</div>


            <div className="heaidngstylefaqsscreen">Do you have an Exchange Policy?</div>
<div className="textstylefaqsscreen">
<ol>
<li>If received a wrong product.</li>
<li>If received a defected/damaged product.</li>
</ol>
<p>However, to complete the replacement of your product, the product should meet the following conditions:</p>
<ul>
<li>If a wrong/defected/damaged product is delivered, we will get it picked up for free.</li>
<li>The product should be sealed in its original product packaging.</li>
<li>The product should be unused and the packaging should not be damaged.</li>
<li>Do not put tape or stickers on the manufacturers box.</li>
<li>The exchange request should be made within 72 hours of receipt of the delivery package.</li>
<li>Once the wrong product is received by us, we will dispatch the correct product within 5-7 working days.</li>
<li>We will expedite exchange once we have received and inspected the product.</li>
</ul>
<p>
Products cannot be returned or exchanged if the package is opened or damaged.
<br />
All the accessories are without any warranty and will only be exchanged if the box is not opened.
<br />
It is important to print out and paste the return label on your return parcel to avoid any inconvenience/delay in the process of your return.
</p>
</div>
        </div>
       

      </section>
    </>
  );
};

export default Faqs;
