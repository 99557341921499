import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LoaderComp from "../../Components/Loader/Loader";
import ProductComp from "../../Components/ProductComponant/ProductComp";
import { getAllProductsApiCall } from "../../Apis/repo";
import "./productByCategory.css";

export default function ProductsByCategory() {
  const location = useLocation();
  const { state } = location;
  const { id } = state || {};
  let [loaderOne, setLoaderOne] = useState(false);
  let [allProductList, setAllProductList] = useState([]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = () => {
    setLoaderOne(true);
    getAllProductsApiCall("", "", id)
      .then(({ data }) => {
        setLoaderOne(false);
        if (data.error_code == 0) {
          setAllProductList((allProductList = data.result));
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        setLoaderOne(false);
        console.log("err", err);
      });
  };

  return (
    <div className="productsByCategoryMainContainer">
      {allProductList.map((item) => {
        return <ProductComp key={item.id} item={item} />;
      })}

      {loaderOne ? <LoaderComp /> : null}
    </div>
  );
}
