import React from "react";
import "./Loader.css";
import { Oval } from "react-loader-spinner";

export default function LoaderComp() {
  return (
    <div className="Loader_Main_Div">
      <div className="Loader_Content">
        <Oval type="Puff" color="#00BFFF" height={50} width={50} timeout={3000} />
      </div>
    </div>
  );
}
