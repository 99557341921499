import React, { useState } from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <>
      <section className="PrivacyMainHeaderContainer">
        <div>Privacy</div>
      </section>
    </>
  );
};

export default Privacy;
