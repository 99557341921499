import React, { useState } from "react";
import "./paymentInfo.css";

const PaymentInfo = () => {
  return (
    <>
      <section className="FAqMainHeaderContainer">
        <div className="faqsHeadingStyle">Payment Information</div>
        <div className="faqsDetaiSubContainer">
          <div className="textstylefaqsscreen">
            At PartnerMobiles, we want to make your online shopping
            experiences the most enjoyable ever. That’s why we focus all our
            brands and policies around you, including payment. We offer you the
            freedom to choose from 4 different, yet equally simple, payment
            options
          </div>

          <div className="paymentmethodtextstyle">
            <ul>
              <li>BANK TRANSFER</li>
              <li>EASY PAISA</li>
              <li>JAZZCASH</li>
              <li>CASH ON DELIVERY</li>
            </ul>
          </div>

          <div className="heaidngstylefaqsscreen">
            What is Cash on Delivery?
          </div>
          <div className="textstylefaqsscreen">
            We know the easiest way to pay for your online shopping in Pakistan
            and online deals are with cash. To enjoy this payment option, simply
            select the “Cash on Delivery” option when you book your order. You
            will receive a call from customer care before your product is sent
            to your door, giving you more than enough time to have the cash
            ready. Our Customer Support Representative might ask you to submit a
            certain portion of the total amount as advance payment on selected
            products for confirmation purpose.
          </div>
          <div className="heaidngstylefaqsscreen">Online Bank Transfer</div>
          <div className="textstylefaqsscreen">
            If you’re more comfortable paying with your online bank account, we
            give you the freedom to do so.After shopping online, simply choose
            “bank transfer” as your mode of payment. By using the latest
            encryption methods, we ensure your data is always safe and secure,
            allowing you to enjoy online shopping in Pakistan.
          </div>

          <div className="heaidngstylefaqsscreen">ATM Transfers</div>
          <div className="textstylefaqsscreen">
            Have an ATM near your home or office? Pay directly with your ATM
            card and enjoy seemingly fast payment. Online Shopping in Pakistan
            has never been easier!
          </div>
          <div className="heaidngstylefaqsscreen">Notes</div>
          <div className="textstylefaqsscreen">
            PartnerMobiles may ask customers to deposit payment in
            advance (partial or full) for certain products as an assurance for
            the order.
          </div>

          <div className="heaidngstylefaqsscreen">Refund Process </div>
          <div className="textstylefaqsscreen">
            If your pre-paid order is canceled due to unavailability of the
            product or any other reason pertaining to PartnerMobiles, you
            shall be entitled to a full refund by PartnerMobiles which
            shall be processed and executed within 7 to 10 working days..
          </div>

          <div className="heaidngstylefaqsscreen">Payment Policy</div>
          <div className="textstylefaqsscreen">
            PartnerMobiles can change the price of the ordered, the price
            fluctuation can be due to currency devaluation, shortage of product
            or any other unforseen incident. In such case PartnerMobiles
            will let the customer know the new price and if the customer agrees
            then only PartnerMobiles will dispatch the product.
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentInfo;
