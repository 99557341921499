import React, { useState } from "react";
import "./warranty.css";

const Warranty = () => {
  return (
    <>
    <section className="FAqMainHeaderContainer">
      <div className="faqsHeadingStyle">Warranty & Returns</div>
      <div className="faqsDetaiSubContainer">
        <div className="returnPolicytextstyle">RETURN POLICIES</div>
        <div className="heaidngstylefaqsscreen">
        Our return policy allows you to exchange a product in two cases
        </div>
        <div className="textstylefaqsscreen">
        <ul className="exchange-policy-container">
      <li>If received a wrong product.</li>
      <li>If received a defected/damaged product.</li>
      <li>
        However, to complete replacement of your product,
        <ul>
          <li>If a wrong/defected/damaged product is delivered, we will get it picked up for free.</li>
          <li>The product should be sealed in its original product packaging.</li>
          <li>The product should be unused and the packaging should not be damaged.</li>
          <li>Do not put tape or stickers on the manufacturers box.</li>
          <li>The exchange request should be made within 72 hours of receipt of the delivery package.</li>
          <li>
            Once the wrong product is received by us, we will dispatch the correct product within 5-7 working days.
          </li>
          <li>
            We will expedite exchange once we have received and inspected the product.
          </li>
        </ul>
      </li>
    </ul>
    </div>


          <div className="heaidngstylefaqsscreen">PLEASE NOTE:</div>
<div className="textstylefaqsscreen">
<ul>
        <li>Products cannot be returned or exchanged if the package is opened or damaged.</li>
        <li>
          All accessories are without any warranty and will only be exchanged if the box is not opened.
        </li>
        <li>
          It is important to print out and paste the return label on your return parcel to avoid any inconvenience/delay in the process of returning.
        </li>
        <li>
          <h4>Which locations do you deliver to?</h4>
          We deliver all over Pakistan.
        </li>
        <li>
          <h4>What are your delivery times?</h4>
          <ul>
            <li>
              For cities Rawalpindi, Lahore, Islamabad, Faisalabad, Sheikhupura and Kasur delivery time will be 24 hours (Mobile Phones only).
            </li>
            <li>
              For locations other than the mentioned ones delivery time will be 2-3 business days.
            </li>
          </ul>
        </li>
      </ul>
</div>


<div className="heaidngstylefaqsscreen">How long would it take for me to receive my order?</div>
<div className="textstylefaqsscreen">
      <ul>
        <li>
          We would make every effort to deliver your order within 2-3 business days.
        </li>
        <li>
          <h4>Is it necessary for me to provide identification when I receive the package?</h4>
          No, it is not necessary. If you are not available at that place then other person can receive your parcel with your permission.
        </li>
      </ul>
    </div>
      </div>
     

    </section>
  </>
  );
};

export default Warranty;
