import React, { useState } from "react";
import "./terms.css";

const Terms = () => {
  return (
    <>
      <section className="TermsMainHeaderContainer">
        <div>Terms & Conditions</div>
      </section>
    </>
  );
};

export default Terms;
