import React, { useState } from "react";
import "./aboutUs.css";

const AboutUs = () => {
  return (
    <>
      <section className="AboutMainHeaderContainer">
        <div>About</div>
      </section>
    </>
  );
};

export default AboutUs;
