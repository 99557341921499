import { URL } from "../Constants/Constant";
import axios from "axios";

const Token = localStorage.getItem("Token");

export async function AdminLoginApiCall(object) {
  let route = URL.concat("project/api/user/login");
  console.log(`VerifyUser Request : ${route} REQUEST`, object);
  let apiRes = null;
  try {
    apiRes = await axios({
      method: "POST",
      url: route,
      data: object,
    });
  } catch (err) {
    apiRes = err;
    return apiRes;
  } finally {
    return apiRes;
  }
}

export const AddAndEditCategoryApiCall = async (data) => {
  return await axios.post(URL.concat("project/api/category/post"), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const getAllCategoriesApiCall = async (token) => {
  return await axios.get(URL.concat(`project/api/category/get-all`), {
    Authorization: token,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const getAllProductsApiCall = async (id, name, categoryID, token) => {
  let params = {};
  if (id) params.id = id;
  if (name) params.name = name;
  if (categoryID) params.category_id = categoryID;
  return await axios.get(URL.concat(`project/api/product/get-all`), {
    // Authorization: token,
    params,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const DeleteCategoryApiCall = async (data) => {
  return await axios.post(URL.concat(`project/api/category/delete`), data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const AddAndEditProductApiCall = async (data) => {
  return await axios.post(URL.concat("project/api/product/post"), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

export const DeleteProductApiCall = async (data) => {
  return await axios.post(URL.concat(`project/api/product/delete`), data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    },
    "axios-retry": {
      retries: 5,
    },
  });
};

// export async function getAllCategoriesApiCall(token) {
//   let route = URL.concat(`project/api/category/get-all`);
//   console.log('getPersonalCardById Request : ', route);
//   let apiRes = null;
//   try {
//     apiRes = await axios({
//       method: 'GET',
//       url: route,
//     });
//   } catch (err) {
//     apiRes = err;
//     return apiRes;
//   } finally {
//     return apiRes;
//   }
// }
