import React, { useState } from "react";
import "./productComp.css";
import EditProductCategory from "../EditProductCategory";
import { useNavigate } from "react-router-dom";
import { URL } from "../../Constants/Constant";
import { EmptyImage } from "../../Assets";

const ProductComp = ({ item, isDashboard }) => {
  const navigate = useNavigate();

  let [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          if (isDashboard) {
            return null;
          } else {
            navigate("/Productdetail", { state: { id: item.id } });
          }
        }}
        className={
          isDashboard
            ? "ProductswiperMainContentContainer"
            : "swiperMainContentContainer"
        }
      >
        <div className="SwiperImageContainer">
          <img
            src={
              item.product_id_FK_images
                ? URL.concat(item.product_id_FK_images[0].image)
                : EmptyImage
            }
            alt="Image"
            className="SwiperImageStyle"
          />
        </div>

        <div className="SwiperContentContainer">
          {/* <div className="SwiperHeadingContainer"> */}
          <div>
            <p className="SwiperLocationTextNameStyle">{item.product_name}</p>
          </div>
          <p className="SwiperLocationTextStyle">{item && item.description}</p>
          <div className="PriceContainerStyle">
            <div className="SwiperPrice_Text_Style">
              {item && item.product_price}
            </div>
            <div className="RsTextStyle">Rs</div>
          </div>
        </div>

        {isDashboard ? (
          <div className="Product_CategoryButtonWrapper">
            <div className="CatButtonStyle1">Delete</div>
            <div
              className="CatButtonStyle"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit
            </div>
          </div>
        ) : null}
      </div>
      <EditProductCategory setIsEdit={setIsEdit} isEdit={isEdit} />
    </>
  );
};

export default ProductComp;
